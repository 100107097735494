import dextools from '../../images/partners/dextools.png';
import tronscan from '../../images/partners/tronscan.svg';
import sunswap from '../../images/partners/sunswap.png';

export const partnersList = [

  {
    name: 'Dextools',
    link: 'https://www.dextools.io/app/ru/tron/pair-explorer/TNsne4Qq1YBbk4MeBFMfvMH6y7EYHZJw6k?t=1724698120031',
    image: dextools,
  },
  {
    name: 'Tronscan',
    link: 'https://tronscan.org/#/',
    image: tronscan,
   },
   {
     name: 'Sunswap',
     link: 'https://sun.io/?lang=en-US#/v3/swap ',
     image: sunswap,
    },
]