export const content = [
  {
    title: 'Phase 1: CrazyPepe Begins',
    items: [
     '\u2713\u00a0Launch on the TRON blockchain',
     '\u2713\u00a0Grow the community to 500+ holders',
     '\u2713\u00a0Start building the CrazyPepe fanbase',
    ]
  },
  {
    title: 'Phase 2: Meme Madness',
    items: [
      '\u2713\u00a0Partner with key projects',
      '\u2713\u00a0Kick off viral marketing campaigns',
      '\u2713\u00a0Host special events and content for holders',
      '\u2713\u00a0Expand to 2000+ holders',
      '\u2713\u00a0Get listed on CoinGecko and CoinMarketCap',
      '\u2713\u00a0Get listed on centralized exchanges',
    ]
  },
  {
    title: 'Phase 3: CrazyPepe Takeover',
    items: [
      '\u2713\u00a0Partner with projects for utility',
      '\u2713\u00a0Enable cross-chain features',
      '\u2713\u00a0Create CrazyPepe-themed surprises',
      '\u2713\u00a0Get listed on top-tier exchanges',
      '\u2713\u00a0Achieve 10,000+ holders',
      '\u2713\u00a0Make a significant impact in the market'
    ]
  }
]