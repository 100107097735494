
import { Carried } from './components/carried/carried';
import { Footer } from './components/footer/footer';
import { Header } from './components/header/header';
import { Hero } from './components/hero/hero';
import { Tokenomics } from './components/tokenomics/tokenomics';

function App() {
  return (
    <div>
      <Header />
      <main>
        <Hero />
        <Tokenomics />
        <Carried />
      </main>
      <Footer />
    </div>
  );
}

export default App;
