import styles from './tokenomics.module.scss';
import movingPepe from '../../images/moving.gif';

export const Tokenomics = () => {
  return (
    <section className={styles.wrap} id='tokenomics'>
      <div className={styles.animatedPepe}>
        <div className={styles.animatedPepeInner}>
          <img src={movingPepe} className={styles.movingPepeImage} alt='pepe' />
        </div>
      </div>
      <h2 className={styles.title}>TOKENOMICS</h2>
      <div className={styles.content}>
        <div className={styles.inner}>
          <div>Ticker: $CRAZYPEPE</div>
          <div>Name:  CrazyPepe</div>
          <div>Contract: TEpYoTbDq9TTNSUcP8xid38es5r5UpeBEL<span className={styles.contract}></span></div>
          <div>Supply: 1.000.000.000</div>
          <div>Tax: 0/0</div>
        </div>
      </div>
    </section>
  )
}