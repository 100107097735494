import styles from './hero.module.scss';
import logo from '../../images/logo.png';
import codeRunning from '../../images/code-running.gif';

export const Hero = () => {
  return (
    <section className={styles.wrap}>
      <div className={styles.content}>
        <div className={styles.background}>
          <div className={styles.backgroundInner}>
            <img alt='code running' src={codeRunning} className={styles.backgroundImage} />
          </div>
        </div>
        <h1 className={styles.title}>$CrazyPepe Token</h1>
        <div className={styles.mainContent}>
          <img alt='logo' src={logo} className={styles.logoImage} />
          <p className={styles.subtitle}>$CrazyPepe is a meme coin inspired by the wild and chaotic energy of the Pepe the Frog meme. Launched on the TRON blockchain, this token combines madness and fun, embodying the spirit of the internet culture we all love. $CrazyPepe is more than just a digital asset; it's a symbol that resonates with anyone who enjoys the absurdity and unpredictability of memes.</p>
          <p className={styles.text}>
          With no intrinsic value or expectation of financial return, $CrazyPepe exists purely for entertainment and fun. Join the $CrazyPepe movement on TRON and dive into a world where anything can happen!
          </p>
        </div>
      </div>
      <div className={styles.buttonsWrap}>
        <a href='https://www.dextools.io/app/ru/tron/pair-explorer/TNsne4Qq1YBbk4MeBFMfvMH6y7EYHZJw6k?t=1724698120031' target='_blank' className={styles.link}>SEE CHART</a>
        <a href='https://sun.io/?lang=en-US#/v3/swap' target='_blank' className={styles.link}>BUY NOW</a>
      </div>
    </section>
  )
}